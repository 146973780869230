function buildOptions(services) {
  let options = [];
  let p = services;
  for (var key in p) {
    if (p.hasOwnProperty(key)) {
      let item = p[key];

      options.push(
        <option key={key} value={JSON.stringify(item)}>
          {item.displayName} | price: ${item.price}
        </option>
      );
    }
  }

  return options;
}

/**
 * Validates email. When a email is valid, it returns true.
 * otherwise false.
 * @param {email} email 
 * @returns 
 */
function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
   * Checks the phone number is one of the following formats.
   * (123) 456-7890
   * (123)456-7890
   * 123-456-7890
   * 1234567890
   * @param {phoneNumber} value
   */
function validatePhoneNumber(phoneNumber) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(phoneNumber)
}

const events = [
  {
    id: "a",
    title: "Mike Cain",
    start: "2022-09-16T12:30:00",
    end: "2022-09-16T14:30:00",
    allDay: false,
  },
  {
    id: "a",
    title: "Erin Hancock",
    start: "2022-09-16T14:30:00",
    end: "2022-09-16T19:30:00",
    allDay: false,
  },
];

export { buildOptions, events, validateEmail, validatePhoneNumber };
