export default function ServiceOffered(props) {
  const { title, price, description } = props;
  return (
    <div className="service-offered-card">
      <div className="title">{title}</div>
      <div className="price">${price}</div>
      <div className="description">{description}</div>
    </div>
  );
}
